//
// Typography
// --------------------------------------------------
// Font face
// -------------------------
@font-face {
    font-family: 'GTAmerica';
    src: url('fonts/GT-America/GT-America-Standard-Regular.eot');
    /* IE9 Compat Modes */
    src: url('fonts/GT-America/GT-America-Standard-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/GT-America/GT-America-Standard-Regular.woff2') format('woff2'), /* Modern Browsers */
    url('fonts/GT-America/GT-America-Standard-Regular.woff') format('woff'), /* Modern Browsers */
    url('fonts/GT-America/GT-America-Standard-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'GTSectra';
    src: url('fonts/GT-Sectra/GT-Sectra-Display-Medium-Italic.eot');
    /* IE9 Compat Modes */
    src: url('fonts/GT-Sectra/GT-Sectra-Display-Medium-Italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('fonts/GT-Sectra/GT-Sectra-Display-Medium-Italic.woff2') format('woff2'),
        /* Modern Browsers */
        url('fonts/GT-Sectra/GT-Sectra-Display-Medium-Italic.woff') format('woff'),
        /* Modern Browsers */
        url('fonts/GT-Sectra/GT-Sectra-Display-Medium-Italic.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

// Headings
//
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: $headings-color;
    font-family: $font-family-secondary;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: $line-height-computed;
    margin-bottom: $line-height-computed;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: ($line-height-computed / 2);
    margin-bottom: ($line-height-computed / 2);
}

h1,
.h1 {
    font-size: $font-size-h1;
    @include media(">=tablet") {
        font-size: $font-size-h1-lg;
    }
}

h2,
.h2 {
    font-size: $font-size-h2;
    @include media(">=tablet") {
        font-size: $font-size-h2-lg;
    }
}

.h2-sm {
    font-size: $font-size-h2;
    @include media(">=tablet") {
        font-size: 30px;
    }
}

h3,
.h3 {
    font-size: $font-size-h3;
    @include media(">=tablet") {
        font-size: $font-size-h3-lg;
    }
}

h4,
.h4 {
    font-size: $font-size-h4;
}

h5,
.h5 {
    font-size: $font-size-h5;
    font-weight: 600;
}

h6,
.h6 {
    font-size: $font-size-h6;
}

h4 {
    .icon-arrow-light-right {
        font-size: 18px;
        position: relative;
        top: 2px;
    }
}

.text-lead {
    font-size: $font-size-md;
    @include media(">=tablet") {
        font-size: $font-size-lg;
    }
}

.text-label {
    font-size: $font-size-sm;
    text-transform: uppercase;
    line-height: (20em/12);
    &.computed {
        line-height: 1.05;
    }
}

.font-light {
    font-weight: normal;
    font-family: 'LatoLatinWebLight';
}

.font-normal {
    font-weight: normal;
}

.font-semibold {
    font-weight: normal;
    font-family: 'LatoLatinWebSemibold';
}

// Body text
// -------------------------
@include paragraph-definition;

// Emphasis & misc
// -------------------------
// Alignment
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-nowrap {
    white-space: nowrap;
}

.text-normal-wrap {
    white-space: normal;
}

// Transformation
.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-introduction {
    font-size: 20px;
    margin-bottom: 56px;
}

.clear-padding {
    padding: 0 !important;
}

.clear-padding-right {
    padding-right: 0 !important;
}

@include media("<tablet") {
    .text-uppercase-xs {
        text-transform: uppercase;
        font-size: 80%;
    }
    .clear-margin-bottom-xs {
        margin-bottom: 0
    }
    .clear-padding-xs {
        padding: 0 !important;
    }
}

.text-through {
    text-decoration: line-through;
}

.text-base {
    color: $text-color;
}

.text-dark {
    color: $text-color;
}

.text-primary {
    color: $brand-primary;
}

.text-secondary {
    color: $brand-secondary;
}

.lead {
    font-size: 20px;
}

.text-bold {
    font-weight: bold;
}

.text-big {
    font-size: 120%;
}

// Lists
//
ul,
ol {
    margin-top: 0;
    margin-bottom: 20px;
    padding-left: 25px;

    li {
        padding-left: 15px;
    }

    ul,
    ol {
        margin-bottom: 0;
    }
}

// List options
//
.list-unstyled {
    @include list-unstyled;
}

.list-small-padding {
    padding-left: 15px;
}

.list-inline {
    > li {
        display: inline-block;
    }
}

.highlight {
    background: $brand-primary !important;
    color: #FFF !important;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
