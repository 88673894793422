.vertical-align-middle {
    @include make-vertical-align-middle();
}

.vertical-align-top {
    @include make-vertical-align-top();
}

.vertical-align-bottom {
    @include make-vertical-align-bottom();
}


.vertical-align-middle-right {
    @extend .vertical-align-middle;
    justify-content: flex-end;
}

.vertical-align-middle-center {
    @extend .vertical-align-middle;
    justify-content: center;
}

@include media("<tablet") {
    .vertical-align-middle-xs {
        @include make-vertical-align-middle();
    }
}

.vertical-line-base {
    display: inline-block;
    height: 13px;
    margin: 0 11px;
    position: relative;
    &:before{
        content: "";
        display: block;
        height: 13px;
        position: absolute;
        top: 2px;
        width: 2px;
    }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
     -khtml-user-select: none; /* Konqueror */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}

.pswp__container{
     transition:.3s ease-in-out all;
}

::-ms-clear { display:none; }

.overflow-h {
    overflow: hidden;
}