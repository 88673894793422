.timeline-row {
    overflow: hidden;
}

.timeline-wrapper {
    margin: -70px 0 0 0;
}

.timeline {
    box-sizing: border-box;
    position: relative;
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
    &:not(.timeline--horizontal)::before {
        background-color: #ddd;
        bottom: 0;
        content: '';
        left: 50%;
        margin-left: -2px;
        position: absolute;
        top: 0;
        width: 4px;
        z-index: 1;
    }
}

.timeline__wrap {
	overflow: hidden;
    position: relative;
    max-height: 450px;
    height: 80vh;
	z-index: 2;
}

.timeline__item {
	font-size: 1rem;
	position: relative;
	width: 50%;
    z-index: 2;
    &::after {
        background-color: #4c9399;
        border: 2px solid #efe6dd;
        border-radius: 50%;
        content: '';
        height: 20px;
        position: absolute;
        right: -10px;
        transform: translatey(-50%);
        top: 50%;
        width: 20px;
        z-index: 1;
    }
    &.brown {
        &::after {
            background-color: #b5997c;
        }
    }
    &.animated {
        animation-duration: 1s;
        animation-fill-mode: both;
        opacity: 0;
    }
    &.fadein {
        animation-name: fadein;
    }
}

.timeline__item--left {
    left: 0;
}

.timeline__item--right {
	left: 50%;
    padding: 10px 0 10px 40px;
    &::after {
        left: -10px;
    }
    .timeline__content {
        &::before {
            border-bottom: 10px solid transparent; 
            border-right: 12px solid #ccc; 
            border-left: none;
            border-top: 10px solid transparent;
            left: -12px;
        }
        &::after {
            border-bottom: 9px solid transparent; 
            border-right: 11px solid #fff; 
            border-left: none;
            border-top: 9px solid transparent;
            left: -10px;
        }
    }
}

.timeline__content {
    border-radius: 10px;
    color: $font-default-color;
	display: block;
    position: relative;

    h2 {
        font-family: $font-family-secondary;
        font-size: 27px;
        margin: 30px 0 10px;
    }
    p {
        font-family: $font-family-base;
        font-size: 16px;
        line-height: 28px;
        margin-top: 60px;
    }
}

.timeline--horizontal {
	font-size: 0;
    padding: 0 0 115px 0;
    white-space: nowrap;

    .timeline-divider {
        background-color: #ddd;
        display: block;
        height: 1px;
        left: 10px;
        position: absolute;
        transform: translatey(-50%);
        right: 0;
        z-index: 1;
    }
    .timeline__items {
        transition: all 0.8s;
        will-change: transform;
    }
    .timeline__item {
        display:  inline-block;
        left:0;
        position: relative;
        transition: none;
        white-space: normal;
        top: 50%;
        transform: translate(0, -50%);
        padding-right: 60px;
        height: 200px !important;
        &::after {
            right: auto;
        }
        .timeline__item__inner {
            display: table;
            height: 100%;
            width: 100%;
        }
        .timeline__content__wrap {
            display: table-cell;
            margin: 0;
            padding: 0;
            vertical-align: middle;
        }
        .timeline__content::before {
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid #ccc;
            left: 50%;
            right: auto;
            transform: translatex(-50%);
            top: 100%;
        }
        .timeline__content::after {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #fff;
            left: 50%;
            right: auto;
            transform: translatex(-50%);
            top: 100%;
        }
        &--bottom {
            transform: translate(0, -50%) !important;
        }
    }
}

.timeline-nav-button {
	outline: none;
	position: absolute;
    bottom: 0px;
    top: auto !important;
    height: 100px;
    width: 100px;
    background: transparent;
    border: none;
    z-index: 100;

    &:disabled {
        opacity: .5;
        pointer-events: none;
    }
    &::before {
        background-position: center center;
        background-repeat: no-repeat;
        content: '';
        display: block;
        position: absolute;
        transform:  translatey(-50%);
        top: 50%;
        left: 0;
        color: #efe6dd;
    }
    &--prev {
        left: 0;
        &::before {
            font-family: "kosciol-webfont";
            font-size: 100px;
            content: '\e009';
            transform: translateY(-50%) rotate(180deg);
        }
    }
    &--next {
        right: 0;
        &::before {
            font-family: "kosciol-webfont";
            font-size: 100px;
            content: '\e009';
        }
    }
}

.timeline--mobile {
    padding: 0;
    &::before {
        left: 10px !important;
        margin: 0 !important;
    }
    .timeline__item {
        left: 0;
        padding-left: 40px;
        padding-right: 0;
        width: 100%;
        &::after {
            left: 2px;
            margin: 0;
        }
        .timeline__content {
            &::before {
                left: -12px;
                border-bottom: 12px solid transparent; 
                border-right: 12px solid #ccc; 
                border-left: none;
                border-top: 12px solid transparent;
            }
            &::after {
                left: -10px;
                border-bottom: 10px solid transparent; 
                border-right: 10px solid #fff;
                border-left: none;
                border-top: 10px solid transparent; 
            }
        }
    }
}

@keyframes fadein {
	0% {
		opacity: 0;
		top: 70px;
	}
	100% {
		opacity: 1;
		top: 0px;
	}
}

@keyframes liftup {
	0% {
		top: 0px;
	}
	100% {
		top: -15px;
	}
}

@include media("<tablet") {
    .timeline-nav-button {
        height: 80px;
        width: 80px;

        &--prev {
            &::before {
                font-size: 80px;
            }
        }
        
        &--next {
            &::before {
                font-size: 80px;
            }
        }
    }
}