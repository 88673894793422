/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0 0 50px 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow {
    display: none !important;
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-dots {
    position: absolute;
    bottom: 10px;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    list-style: none;

    font-family: $font-family-base;
    font-size: 18px;
    > li {
        position: relative;

        &::before,
        &::after {
            content: ' ';
            display: inline-block;
            height: 1px;
            width: 0;
            background-color: #fff;
            border-bottom: 0;
            margin-bottom: 0;
            transform: scaleX(1);
            transition: width .5s ease-in-out;
            position: relative;
            top: -6px;
        }

        &.slick-active {
            &::after{
                width: 20vw;
            }
        }

        a {
            padding: 0 10px;
        }
    }
}

.section-color-3 .slick-dots > li {
    &::before,
    &::after {
        background-color: $clay;
    }
}

.slider-image-wrapper {
    img {
        width: 100%;
    }
}

@include media("<desktop") {
    .slick-dots {
        position: relative;
        margin-bottom: 40px;
    }

    .slick-list {
        padding-bottom: 20px;
    }
}

@include media("<phone-lg") {
    .slick-dots {
        width: 100%;
        margin-left: 30px;

        > li {
            &.slick-active {
                &::after{
                    width: 10vw;
                }
            }
        }
    }

    .slick-slider {
        transform: translateX(-30px);

        .slider-image-wrapper,
        .slick-list {
            width: 100vw;
        }
    }

    .carousel-next-link-centered {
        display: none;
    }
}