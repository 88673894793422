.page-header {
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;

  .navbar-header {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .header-video-wrapper {
    width: 100%;
    padding-top: 75%;

    @include media("<desktop") {
      padding-top: 40%;
    }

    .video-switcher {
      opacity: 0;
      transition: opacity .2s linear;

      &.show {
        opacity: 1;
      }
    }
  }

  .video-switch {
    &::after {
      content: none;
    }

    &:hover {
      color: map-get($colors, 'turquoise');
    }
  }

  .header-title {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 30px;
    text-align: center;

    h1 {
      margin-top: 75px;
      margin-bottom: 20px;
      color: $font-default-color;
      font-family: $font-family-secondary;
      font-size: 200px;
    }

    p {
      margin: 0;
      color: $font-default-color;
      font-family: $font-family-secondary;
      font-size: 30px;
      line-height: 42px;
    }
  }

  .header-title-left {
    text-align: left;
    width: 100%;
  }

  .header-title-normal-size {
    h1 {
      font-size: 45px;
      @include media(">=tablet") {
        font-size: 65px;
      }
      @include media(">=desktop") {
        font-size: 75px;
      }
      @include media(">=desktop-lg") {
        font-size: 100px;
      }
    }
  }

  $scrollDownSize: 80px;
  .scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    width: $scrollDownSize;

    color: #fff;
    font-size: $scrollDownSize;

    cursor: pointer;

    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: bounce-2;
    animation-timing-function: ease;

    @include media("<desktop") {
      top: 70%;
    }
    @include media(">=desktop") {
      bottom: 50px;
    }
    @media (max-height: 400px) {
      font-size: ceil(3*$scrollDownSize/4);
      bottom: 10px;
      top: unset;
    }

    i{
      &::before{
        transform: rotate(180deg);
        position: relative;
        display: block;
      }
    }
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
  }
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: $video-overlay;
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@include media("<desktop") {
  .page-header {
    .header-title {
      top: 40%;
      width: 90%;

      .row {
        width: 80%;
        margin: 0 auto;
      }
      
      h1 {
        margin-top: 0;
        font-size: 42px;
      }

      p {
        font-size: 18px;
        line-height: 20px;
      }
    }

    .header-title-left {
      text-align: center;
      padding: 0 5%;
    }
  }
}

@include media("<tablet") {
  .page-header {
    .header-title {
      h1 {
        font-size: 32px;
      }
    }
  }
}

.lang-switch {
  left: 130px;
}