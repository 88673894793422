//
// Base
// --------------------------------------------------
// Reset the box-sizing
* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

// Body reset
html,
body {
    height: 100% !important;
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    margin: 0;
    font-family: $font-family-base;
    > * {
        position: relative;
    }
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

textarea {
    border: 0;
    background: #f0f1f2;
    resize: vertical;
}

// Links
a {
    display: inline-block;
    text-decoration: none;
    color: $font-default-color;
    @include transition(all, linear, $default-transition-time);
    &:hover,
    &:focus,
    &.active {
        text-decoration: none;
        outline: none;
    }
    &:hover,
    &:focus {
        text-decoration: none;
    }
    &::after {
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 1px;
        width: 0;
        transition: width $default-transition-time $default-transition-animation;
        will-change: width;

        content: '';
        background: $font-default-color;
    }

    &:hover {
        &::after {
            width: 100%;
        }
    }

    &.secondary-underline {
        display: inline-block;
        &::after {
            background: #af5f46;
        }
    }
}

@keyframes bounce-1 {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

.clear-margin-all {
    margin: 0;
}

hr {
    margin: 50px 0;
    border: 0;
    border-bottom: 1px solid $font-default-color;

    &.brown {
        border-color: $clay;
    }
}

.link-default {
    font-family: $font-family-secondary;
    font-size: 22px;
}

.font-18 {
    font-size: 18px;
}

.font-22 {
    font-size: 22px;
}

.container-fluid {
    padding: 0 100px;

    &.section-default,
    &.section-centered {
        @include section-default();
    }

    .row-centered,
    &.section-centered {
        text-align: center;
    }
}

.container-force-0-distance {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}