//
// Responsive: Utility classes
// --------------------------------------------------

@at-root {
  @-ms-viewport {
    width: device-width;
  }
}


// Visibility utilities
//@include responsive-invisibility('.visible-xs');
//@include responsive-invisibility('.visible-sm');
//@include responsive-invisibility('.visible-md');
//@include responsive-invisibility('.visible-lg');

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@include media("<desktop") {
  .visible-sm-block {
    display: block !important;
  }
  .visible-sm-inline {
    display: inline !important;
  }
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@include media("<tablet") {
  .visible-xs-block {
    display: block !important;
  }
  .visible-xs-inline {
    display: inline !important;
  }
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@include media("<tablet") {
  .hidden-xs{
    display: none !important;
  }
}

@include media(">tablet", "<desktop") {
  .hidden-sm{
    display: none !important;
  }
}

@include media(">desktop", "<desktop-lg") {
  .hidden-md{
    display: none !important;
  }
}

@include media(">desktop-lg") {
  .hidden-lg{
    display: none !important;
  }
}

//--------------------------------------
.device-v-xs {
  display: none;
}
.device-v-sm {
  display: none;
}
.device-v-md {
  display: none;
}
.device-v-lg{ 
  display: none;
}
@include media("<tablet") {
  .device-v-xs {
    display: block !important;
  }
}
@include media(">=tablet", "<desktop") {
  .device-v-sm {
    display: block !important;
  }
}
@include media(">=desktop", "<desktop-lg") {
  .device-v-md {
    display: block !important;
  }
}
@include media(">=desktop-lg") {
  .device-v-lg {
    display: block !important;
  }
}



/*
@media (max-width: $screen-xs-max) {
 // @include responsive-visibility('.visible-xs');
}
.visible-xs-block {
  @media (max-width: $screen-xs-max) {
    display: block !important;
  }
}
.visible-xs-inline {
  @media (max-width: $screen-xs-max) {
    display: inline !important;
  }
}
.visible-xs-inline-block {
  @media (max-width: $screen-xs-max) {
    display: inline-block !important;
  }
}

@media (max-width: $screen-xs-max) {
 // @include responsive-visibility('.visible-xs');
}
.visible-xs-block {
  @media (max-width: $screen-xs-max) {
    display: block !important;
  }
}
.visible-xs-inline {
  @media (max-width: $screen-xs-max) {
    display: inline !important;
  }
}
.visible-xs-inline-block {
  @media (max-width: $screen-xs-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @include responsive-visibility('.visible-sm');
}
.visible-sm-block {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: block !important;
  }
}
.visible-sm-inline {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: inline !important;
  }
}
.visible-sm-inline-block {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @include responsive-visibility('.visible-md');
}
.visible-md-block {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: block !important;
  }
}
.visible-md-inline {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: inline !important;
  }
}
.visible-md-inline-block {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-lg-min) {
  @include responsive-visibility('.visible-lg');
}
.visible-lg-block {
  @media (min-width: $screen-lg-min) {
    display: block !important;
  }
}
.visible-lg-inline {
  @media (min-width: $screen-lg-min) {
    display: inline !important;
  }
}
.visible-lg-inline-block {
  @media (min-width: $screen-lg-min) {
    display: inline-block !important;
  }
}
@media (max-width: $screen-xs-max) {
  //@include responsive-invisibility('.hidden-xs');
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  //@include responsive-invisibility('.hidden-sm');
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  //@include responsive-invisibility('.hidden-md');
}

@media (min-width: $screen-lg-min) {
 // @include responsive-invisibility('.hidden-lg');
}
*/

// Print utilities
//
// Media queries are placed on the inside to be mixin-friendly.

// Note: Deprecated .visible-print as of v3.2.0

//@include responsive-invisibility('.visible-print');

@media print {
 // @include responsive-visibility('.visible-print');
}
.visible-print-block {
  display: none !important;

  @media print {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;

  @media print {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;

  @media print {
    display: inline-block !important;
  }
}

@media print {
//  @include responsive-invisibility('.hidden-print');
}
