$menuColor: $font-default-color;

.nav-tabs {
  padding: 0;
  margin: 0;
}

.main-menu-wrapper {
  position: relative;
  margin-top: 0px;

  display: flex;
  justify-content: center;

  z-index: 1;

  @include media("<desktop") {
    display: none;
  }

  nav {
    width: 100%;
  }

  a {
    position: relative;
    font-size: 16px;
    color: $menuColor;
  }
}

.main-menu {
  @include flex-list();

  .main-menu-home {
    display: none;
  }

  .active a::after {
    width: 100%;
  }
}

.slicknav_menu {
  &, & * {
    background: transparent;
  }

  .list-inline > li {
    display: block;
  }

  a::after {
    @include resetTransition();
    background: transparent;
  }

  @include media(">=desktop") {
    display: none;
  }
  
  .slicknav_btn {
    margin: 10px;

    @include media("<desktop") {
      position: absolute;
      top: 0;
      right: 0;
      z-index: $zindex-menu-btn;
    }

    a {
      @include resetTransition();
    }
  }

  .slicknav_icon {
    width: auto;
    height: auto;
  }

  .slicknav_icon-bar {
    box-shadow: none;
    width: 30px;
    height: 1px;
  
    &+.slicknav_icon-bar {
      margin-top: 9px;
    }
  }

  .slicknav_nav {
    padding: 50px 10px 110px 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: $zindex-menu;
    transform: translateX(-50%) translateY(-50%);
    background-color: map-get($colors, 'turquoise');

    transition: background-color .5s linear;
    will-change: background-color;

    &.slicknav_hidden {
      background-color: transparent;

      a {
        opacity: 0;
      }
    }

    a {
      display: inline-block;
      padding: 5px 0;
      margin: 15px 15px 0 15px;
      font-size: $font-text-large;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;

      @include resetTransition();
      transition: all .2s linear;
      will-change: all;
    }

    a:hover {
      border-radius: 0;
      background: inherit;
      color: inherit;
    }

    .active a {
      border-bottom: 1px solid;
    }

    .main-menu-home {
      display: block;
    }
  }
}

