.main-logo {
  position: relative;
  display: block;
  z-index: 10;
  text-align: center;

  @include media("<desktop") {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px 0 0 20px;
  }

  &::after {
    content: none;
    all: unset;
  }

  &:hover {
    &::after {
      content: 'home';
      font-size: 15px;
      font-style: italic;
      font-family: GTSectra,Arial,sans-serif;
      position: absolute;
      bottom: -5px;
      left: 0;
    }
  }

  i {
    font-size: 100px;
    color: $font-default-color;
  }

  @include media("<tablet") {
    i {
      font-size: 80px;
    }
  }
}

.link-mapa {
  padding: 20px;
  position: absolute;

  color: $font-default-color;
  font-size: 14px;
  text-align: center;

  background: map-get($colors, 'earth');

  @include media("<desktop") {
    bottom: 0;
    left: 0;
    z-index: $zindex-link-mapa;
  }
  @include media(">=desktop") {
    top: 0;
    right: 0;
  }

  &:hover {
    &::after {
      content: none;
    }
    background: map-get($colors, 'turquoise');
  }

  i {
    display: block;
    font-size: 30px;
  }
}

.is-here {
  position: absolute;
  left: 16%;
  top: 165px;

  font-family: $font-family-secondary;
  font-size: 23px;
}

.coordinates {
  position: absolute;
  left: 27%;

  font-family: $font-family-base;
  font-size: 18px;

  >span {
    display: block;

    font-size: 18px;
    color: $font-default-color;
  }
}

.picture-container {
  .picture-wrapper {
    margin: 0 auto;
    position: relative;
    width: 50%;

    font-family: $font-family-secondary;
    font-size: 22px;

    a {
      display: inline-block;
    }

    img {
      max-width: 100%;
      margin-bottom: 30px;
    }
  }
}

.tile-movie {
  position: relative;
  height: 100%;
  width: 50%;
  overflow: hidden;

  &::before {
    content: '';
    float: left;
    padding-top: 100%;
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
  }
}

.tile-text {
  color: $font-default-color;
  font-family: $font-family-base;
  font-size: 18px;
  overflow: auto;

  > div {
    position: absolute;
    left: 10%;
    width: 80%;
  }

  &.color-1 {
    background-color: #4c9399;
  }
  
  ul {
    padding: 20px 40px;
    li {
      font-family: $font-family-base;
    }
  }
}

.tile-models {
  display: flex;
  flex: 1 1 25%;
  flex-wrap: wrap;
  justify-content: space-between;

  >* {
    flex: 1 1 25%;
    max-width: 25%;

    &::before {
      content: '';
      float: left;
      padding-top: 100%;
    }
  }
}

.tile-images {
  display: flex;
  flex: 1 1 25%;
  flex-wrap: wrap;
  justify-content: space-between;

  >* {
    flex: 1 1 25%;

    &::before {
      content: '';
      float: left;
      padding-top: 100%;
    }
  }
}

.section-tile-v {
  .tile-images {
    flex: 1 1 50%;

    >* {
      flex: 1 1 50%;
    }  
  }
}

.tile-image {
  position: relative;

  &.image-1 {
    background: url('../assets/images/tile/tile1.jpg') center;
    background-size: cover;
  }

  &.image-2 {
    background: url('../assets/images/tile/tile2.jpg') center;
    background-size: cover;
  }

  &.image-detal-1 {
    background: url('../assets/images/detale/tile3.jpg') center;
    background-size: cover;
  }

  &.image-detal-2 {
    background: url('../assets/images/detale/tile1.jpg') center;
    background-size: cover;
  }

  &.image-detal-3 {
    background: url('../assets/images/detale/tile2.jpg') center;
    background-size: cover;
  }

  &.image-miejsce-1 {
    background: url('../assets/images/miejsce/kwadrat1.jpg') center;
    background-size: cover;
  }

  &.image-miejsce-2 {
    background: url('../assets/images/miejsce/kwadrat2.jpg') center;
    background-size: cover;
  }

  &.image-miejsce-3 {
    background: url('../assets/images/miejsce/kwadrat3.jpg') center;
    background-size: cover;
  }

  &.image-dziedziczenie-1 {
    background: url('../assets/images/dziedzictwo/6.png') center;
    background-size: cover;
  }

  &.image-dziedziczenie-2 {
    background: url('../assets/images/dziedzictwo/2.png') center;
    background-size: cover;
  }
  &.image-dziedziczenie-3 {
    background: url('../assets/images/dziedzictwo/5.png') center;
    background-size: cover;
  }
  &.image-dziedziczenie-4 {
    background: url('../assets/images/dziedzictwo/3.png') center;
    background-size: cover;
  }
  &.image-dziedziczenie-5 {
    background: url('../assets/images/dziedzictwo/4.png') center;
    background-size: cover;
  }
  &.image-kontakt-1 {
    background: url('../assets/images/kontakt/1.png') center;
    background-size: cover;
  }

  &.image-kontakt-2 {
    background: url('../assets/images/kontakt/2.png') center;
    background-size: cover;
  }
}

.tile-link {
  padding: 30px 43px;
  font-size: 22px;

  &::after {
    content: none;
  }

  &:hover {
    .icon-strzalka-link {
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: bounce-1;
      animation-timing-function: ease;
    }
    &::after {
      all: unset;
      content: '';
      font-family: GTSectra,Arial,sans-serif;
      position: absolute;
      bottom: 40px;
      right: 47px;
    }
  }

  &[class*="color-"] {
    color: $font-default-color;
  }

  &.color-1 {
    background: $clay;
    &:hover{
      background: map-get($colors, 'turquoise');
    }
  }

  &.color-2 {
    background: map-get($colors, 'dark-beige');
    &:hover{
      background: $clay;
    }
  }

  &.color-3 {
    background: #efe6dd;
  }

  &.color-4 {
    background: #4c9399;
  }
  

  i {
    display: block;
    font-size: 50px;
    margin-top: 10px;
    margin-left: 0;
  }
}

.tile-extra-info {
  font-size: 22px;
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.unstyled-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.video-container {
  img {
    height: 100%;
  }
  .video-js {
    width: 100%;
  }
  .look-more {
    font-size: 22px;
    margin-bottom: 60px;
  }
}

.video-player {
  position: relative;
  margin: 0 auto;
  padding: 0;
  line-height: 0;
  max-width: 960px;

  .play {
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $font-default-color;
    cursor: pointer;
    z-index: 1;
  }

  &.video-wrapper {
    overflow: visible;
  }

  &.video-play {
    .play {
      display: none;
    }
  }
}

.map-outline {
  position: relative;
  top: 70px;
  z-index: 1;
}

.list-default {
  list-style: none;
  padding: 0;
  margin: 0;

  font-family: $font-family-secondary;
  font-size: 22px;

  li {
    padding-left: 30px;
    margin-bottom: 1em;
    position: relative;

    &:before {
      font-family: kosciol-webfont;
      font-size: 9px;
      content: '\e020';
      display: inline-block;
      height: 10px;
      width: 10px;
      position: absolute;
      left: 0;
      top: 9px;
    }
  }

  &.list-no-marker {
    li {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }
}

.list-secondary {
  @extend .list-default;

  font-family: $font-family-base;
  font-size: 15px;
  line-height: 30px;

  li {
    &::before {
      color: $clay;
      top: 0;
      font-family: kosciol-webfont;
      content: "\e020";
      font-size: 9px;
    }
  }
}

.h3-normal {
  font-family: $font-family-base;
  font-size: 26px;
  margin-bottom: 60px;
}

.day-night-wrapper {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;

  .line {
    margin: 15px 0;
    display: block;
    height: 1px;
    width: 40px;
    transform: rotate(-20deg);
    background: $font-default-color;
  }

  a {
    &.active {
      color: map-get($colors, 'dark-beige');
    }
  }

  i {
    display: block;
    font-size: 15px;
    padding: 10px 0;
  }
}

.video-wrapper {
  position: relative;
  height: 80vh;
  overflow: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translateX(-50%) translateY(-50%);
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: $video-overlay;
  }
}

.open-model {
  display: block;
  cursor: pointer;
  position: relative;

  &:hover {
    .icon-strzalka-link {
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: bounce-1;
      animation-timing-function: ease;
    }
  }

  

  .link-icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $font-default-color;
    i {
      font-size: 134px;
    }
    .icon-strzalka-link {
      font-size: 50px;
      margin: 0;
      margin-top: 20px;
    }
    div {
      font-size: 18px;
    }
  }
}

.look-more {
  display: inline-flex;
  align-items: center;
}

.emphesize-text,
.look-more {
  font-family: $font-family-secondary;
  margin-top: 30px;
  font-size: 22px;
}

.model-hover {
  position: relative;

  
  &:hover{
    .hover-desc {
      opacity: 1;
    }

    img {
      opacity: .3;
    }
  }

  .hover-desc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    opacity: 0;
    transition: opacity .2s linear;

    i {
      font-size: 100px;
      display: block;
    }
  }
}

.read-more {
  display: inline-flex;
  align-items: center;
  font-family: $font-family-secondary;
  font-size: 22px;
  @include resetTransition();

  &.center {
    justify-content: center;
  }

  i {
    font-size: 28px;
    height: 28px;
    position: relative;
    margin-left: 12px;
  }

  &:hover::after {
    width: 0;
  }
}

.read-more-wrapper {
  opacity: 1;
  transition: opacity 1s ease-in-out;
  @include paragraph-definition;
 
  &:not(.show) {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    margin: 0;
  }

  .read-more-button,
  .read-less-button {
    justify-content: center;
  }

  & + .read-more {
    .read-more-button {
      display: inline-flex;
      align-items: center;
    }
    .read-less-button {
      display: none;
    }
  }

  &.show + .read-more {
    .read-more-button {
      display: none;
    }
    .read-less-button {
      display: inline-flex;
      align-items: center;
    }
  }
}

.sponsors-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 90px;

  img {
    height: 15vh;
    max-height: 170px;
    min-height: 100px;
    padding-right: 30px;
  }
}

.open-dialog {
  cursor: pointer;

  a {
    position: relative;
    img {
      transition: opacity .2s linear;
    }
    &:hover{
      img {
        opacity: .3;
      }
      .hover-desc {
        opacity: 1;
      }
    }
    .hover-desc {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 15px;
      opacity: 0;
      transition: opacity .2s linear;
      i {
        font-size: 100px;
        display: block;
      }
    }
  }
}

.section-img-absolute {
  width: 50%;
  position: absolute;

  img {
    position: relative;
  }
}

.section-img-absolute-bottom-right {
  bottom: 0;
  right: 0;
}

.section-img-absolute-bottom-left {
  bottom: 0;
  left: 0;
}

.section-img-absolut-centered {
  text-align: center;
}

.images-130 {
  margin-top: 90px;
  img {
    max-width: 130px;
  }
}

.k-coordinates {
  font-family: $font-family-secondary;
  font-size: 30px;
  margin-bottom: 45px;
}

.k-title {
  margin-bottom: 20px;
  text-decoration: underline;
}

#map {
  height: 80vh;
}

#map-image {
  height: 90vh;
  background-size: cover;
  background-position: center;
  background-image: url("../assets/images/kontakt/mapka.png");
}

.image-gallery-wrapper {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: space-between;

  a {
    display: inline-block;
    align-self: flex-end;
  }
}

#images-model-jezus-na-teczy {
  width: 75%;
  bottom: -5px;

  @include media("<desktop") {
    width: 50%;
  }
  @include media("<tablet") {
    width: 75%;
  }
}

#images-miejsce-model {
  height: 70vh;
  bottom: -8vh;
}

.link-default {
  &:hover {
    .icon-strzalka-link {
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: bounce-1;
      animation-timing-function: ease;
    }
  }
}

.logo-mkidn {
  background-image: url("../assets/images/logotypy/ministerstwo-kultury.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  height: 120px;

  &:hover {
    background-image: url("../assets/images/logotypy/ministerstwo-kultury_kolor.png");
  }
}

.link-icon {
  &:hover {
    opacity: .3;
  }
}

.row-video-wrapper {
  margin-top: 30px;
}

@include media("<desktop") {
  .sponsors-logo {
    img {
      padding-right: 15px;
    }
  }

  .picture-container {
    .picture-wrapper {
      width: 100%;

      img {
        margin-left: -25%;
      }
    }
  }

  .day-night-wrapper {
    top: auto;
    bottom: 25px;
    @include resetTransition();

    .line {
      display: inline-block;
      transform: rotate(-70deg);
    }
  }

  .section-img-absolute-bottom-left ,
  .section-img-absolute-bottom-right {
    position: relative;
    text-align: center;
    width: 100%;
  }

  .tile-models {
    >* {
      flex: 1 1 30%;
      max-width: 30%;
    }
  }
}

@include media("<tablet") {
  .row-video-wrapper {
    overflow: hidden;
    width: 100vw;
    margin: 30px -30px 0 -30px;
    padding: 0;

    .video-container {
      padding: 0;

    }
  }
  
  .sponsors-logo {
    img {
      min-height: 150px;
    }
  }

  .read-more i {
    font-size: 22px;
  }

  .tile-models {
    >* {
      flex: 1 1 50%;
      max-width: 50%;
    }
  }

  .tile-images {
    display: block;

    >* {
      &::before {
        padding-top: 0;
      }
    }
  }

  .tile-link {
    display: block;
  }

  .tile-image {
    display: block;
    width: 100vw;
    height: 100vw;
  }

  .tile-text {
    width: 100vw;
    height: 100%;
    padding: 30px;
    overflow: auto;

    > div {
      transform: translate(0,0);
      position: static;
    }
  }

  .tile-extra-info {
    position: initial;
  }

  span.tile-link,
  .image-detal-1, .image-miejsce-1, .image-miejsce-3,
  .image-dziedziczenie-2, .image-dziedziczenie-3,
  .image-1, .image-2 {
    display: none;
  }

  #images-miejsce-model {
    height: 60vh;
  }

  .image-gallery-wrapper {
    display: block;

    a {
      padding: 30px;
    }
  }
}

@include media("<phone-lg") {
  .sponsors-logo {
    img {
      min-height: 100px;
    }
  }

  .tile-models {
    >* {
      flex: 1 1 90%;
      max-width: 90%;
    }
  }
}

@media (pointer:coarse) {
  .model-hover {
    .hover-desc {
      font-size: 18px;

      @include infiniteAnimation("bounce-opacity-2", 5s, 0s);

      i {
        margin: 15px;
        line-height: 0;
        padding: 0;
        height: 94px;
      }
    }

    img {
      @include infiniteAnimation("bounce-opacity-1", 5s, 0s);
    }
  }
}

/* Animation Keyframes*/
@keyframes bounce-opacity-1 {
  0% { opacity: 1; }
  30% { opacity: 0.3; }
  100% { opacity: 1; }
}

@-moz-keyframes bounce-opacity-1 {
  0% { opacity: 1; }
  30% { opacity: 0.3; }
  100% { opacity: 1; }
}

@-webkit-keyframes bounce-opacity-1 {
  0% { opacity: 1; }
  30% { opacity: 0.3; }
  100% { opacity: 1; }
}

@-o-keyframes bounce-opacity-1 {
  0% { opacity: 1; }
  30% { opacity: 0.3; }
  100% { opacity: 1; }
}

@keyframes bounce-opacity-2 {
  0% { opacity: 0; }
  30% { opacity: 1; }
  100% { opacity: 0; }
}

@-moz-keyframes bounce-opacity-2 {
  0% { opacity: 0; }
  30% { opacity: 1; }
  100% { opacity: 0; }
}

@-webkit-keyframes bounce-opacity-2 {
  0% { opacity: 0; }
  30% { opacity: 1; }
  100% { opacity: 0; }
}

@-o-keyframes bounce-opacity-2 {
  0% { opacity: 0; }
  30% { opacity: 1; }
  100% { opacity: 0; }
}