//
// Input groups
// --------------------------------------------------
// Base styles
// -------------------------
form {
    form-center {
        left: 50%;
        position: relative;
        transform: translate(-50%, 0);
        width: 50%;
    }
    .label {
        font-size: 20px;
    }
    .form-checkbox {
        font-size: 16px;
        margin-bottom: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    .form-radio {
        margin-right: 30px;
    }
    .form-group {
        @include make-vertical-align-middle();
        margin-bottom: 20px;
        label {
            margin-bottom: 0;
        }
    }
    .form-button-wrapper {
        margin-top: 25px;
    }
}

@include media("<desktop") {
    form {
        &.form-center {
            width: 65%;
        }
    }
}

@include media("<tablet") {
    form {
        .label {
            font-size: 14px;
        }
        &.form-center {
            width: 80%;
            label {
                font-size: 14px;
                margin-bottom: 5px;
            }
            .vertical-align-middle {
                display: block;
            }
            .form-radio {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                .vertical-align-middle {
                    @include make-vertical-align-middle();
                }
            }
            .form-checkbox {
                .vertical-align-middle {
                    @include make-vertical-align-middle();
                }
            }
        }
    }
}

@include media("<phone-lg") {
    form {
        &.form-center {
            width: 95%;
        }
    }
}

label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

.required {
}

.text-required {
}

input,
textarea {
    background: #fff;
    border: 1px solid #b0cbdc;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    border-radius: 0;
    max-width: 570px;
    outline: none;
    padding: 15px 40px 15px 10px;
    width: 100%;
}

input {
    padding: 10px 40px 10px 10px;
    &.small-input {
        max-width: 140px;
    }
    &.datepicker {
        max-width: 130px;
    }
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
    .form-control {
        .high-contrast & {
        }
        position: relative;
        z-index: 2;
        float: left;
        width: 100%;
        margin-bottom: 0;
        &:focus {
            outline: none;
            z-index: 3;
        }
    }
    .btn-input,
    input {
        border: 0;
    }
    input {
        font-size: 16px;
    }
}

.input-gray {
    background-color: #ccc;
    border: 0;
    color: gray;
    padding: 5px;
    &:focus {
        outline: none;
    }
}

// Display as table-cell
// -------------------------
.input-group-addon,
.input-group-btn,
.input-group .form-control {
    display: table-cell;
}

// Addon and addon wrapper for buttons
.input-group-addon,
.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}

// Button input groups
// -------------------------
.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
    > .btn {
        position: relative;
        margin-left: 1px;
        // Bring the "active" button to the front
        &:hover,
        &:focus,
        &:active {
            z-index: 2;
        }
    }
    .btn-clear {
        border: none;
        background: none;
        font-size: 20px;
        padding: 0 15px;
    }
    .icon-search {
        &:before {
            left: 0;
            position: relative;
            top: 3px;
        }
    }
}

.input-xs {
    width: 60px;
}

.input-sm {
    width: 75px;
}

input[type="checkbox"],
input[type="radio"] {
    display: none;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
    min-height: 30px;
    margin-bottom: 0;
    padding-left: 0;
    font-weight: 400;
}

input[type="checkbox"] + label + .error {
    padding-left: 52px;
}

input[type="radio"] + label {
    padding-left: 0;
}

input[type="checkbox"] + label em,
input[type="radio"] + label em {
    background-color: #fff;
    border: 1px solid $input-border;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    left: 0;
    margin-right: 10px;
    max-width: 30px;
    min-width: 30px;
    position: relative;
    top: 0px;
    vertical-align: middle;
    width: 100%;
    &:before {
        background-color: transparent;
        border-radius: 0;
        bottom: 0;
        content: "";
        height: 25px;
        left: 2px;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0px;
        width: 15px;
        z-index: -1;
    }
}

input[type="radio"] + label em {
    &:before {
        left: 0;
    }
}

input[type="checkbox"] + label span,
input[type="radio"] + label span {
    padding-left: 10px;
    width: 100%;
}

@include media("<desktop") {
    input[name="agreement-check"]:checked + label span {
        width: 90%;
    }
}

input[type="radio"] + label span {
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: 400;
}

@include media("<tablet") {
    input[type="radio"] + label span {
        font-size: 16px;
    }
}

input[type="checkbox"]:checked + label span,
input[type="radio"]:checked + label span {
}

.checkbox {
    input[type="checkbox"] + label em {
        &:before {
            top: -3px;
        }
    }
}

input[type="radio"] + label em {
    border-radius: 50%;
}

input[type="checkbox"]:checked + label em,
input[type="radio"]:checked + label em {
    display: table;
    margin-right: 10px;
    position: relative;
    top: 0px;
    &:before {
        background-color: #909a9b;
        z-index: 1;
    }
}

input[type="radio"]:checked + label em {
    margin-right: 10px;
}

input[type="checkbox"]:checked + label,
input[type="radio"]:checked + label {
    margin-left: 0px;
}

input[type="checkbox"] + label em,
input[type="radio"] + label em {
    &:before {
        font-family: "";
        content: "\e051";
        font-size: 16px;
        opacity: 0;
    }
}

input[type="radio"] + label em {
    &:before {
        background: #0040b2;
        border-radius: 50%;
        content: "";
        font-size: 20px;
        height: 14px;
        opacity: 1;
        width: 14px;
    }
}

input[type="radio"]:checked + label em {
    &:before {
        background: #0040b2;
    }
}

input[type="checkbox"]:checked + label,
input[type="radio"]:checked + label {
    padding-left: 0;
}

input[type="checkbox"]:checked + label em {
    color: $brand-secondary;
    &:before {
        background-color: transparent;
        height: 18px;
        width: 22px;
        opacity: 1;
    }
}

input[type="checkbox"].error + label em {
    border: 1px solid $error;
}

input[type="checkbox"] + label em,
input[type="checkbox"]:checked + label em,
input[type="radio"] + label em,
input[type="radio"]:checked + label em {
    &:before {
        @include transition(opacity, ease, $default-transition-time);
    }
}

label.error {
    display: none;
}

label.error {
    display: block;
    color: $error;
    margin-top: 5px;
}

textarea.error,
input.error {
    border: 1px solid $error;
}
