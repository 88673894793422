@charset "UTF-8";

@font-face {
  font-family: "kosciol-webfont";
  src: url("./fonts/webfont/kosciol-webfont.eot");
  src: url("./fonts/webfont/kosciol-webfont.eot?#iefix") format("embedded-opentype"),
    url("./fonts/webfont/kosciol-webfont.woff") format("woff"),
    url("./fonts/webfont/kosciol-webfont.ttf") format("truetype"),
    url("./fonts/webfont/kosciol-webfont.svg#kosciol-webfont") format("svg");
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: "untitled-font-1";
  src: url("./fonts/webfont/untitled-font-1.eot");
  src: url("./fonts/webfont/untitled-font-1.eot?#iefix") format("embedded-opentype"),
    url("./fonts/webfont/untitled-font-1.woff") format("woff"),
    url("./fonts/webfont/untitled-font-1.ttf") format("truetype"),
    url("./fonts/webfont/untitled-font-1.svg#untitled-font-1") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "kosciol-webfont" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "kosciol-webfont" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bigger-size:before {
  content: "\e000";
}

.icon-close-map-homepage:before {
  content: "\e001";
}

.icon-close-model:before {
  content: "\e002";
}

.icon-dropdown:before {
  content: "\e003";
}

.icon-readmore:before {
  content: "\e003";
}

.icon-readless {
  transform: rotate(180deg);
  &:before {
    content: "\e003";
  }
}

.icon-facebook:before {
  content: "\e004";
}

.icon-kamera-ikona:before {
  content: "\e005";
}

.icon-komp-ikona:before {
  content: "\e006";
}

.icon-mikrofon:before {
  content: "\e007";
}

.icon-sygnet-home:before {
  font-family: "untitled-font-1" !important;
  content: "b";
}

.icon-strzalka-slider:before {
  content: "\e009";
}

.icon-strzaka-stopka:before {
  content: "\e00a";
}

.icon-slider-braz-strzalka:before {
  content: "\e00b";
}

.icon-pinterest:before {
  content: "\e00c";
}

.icon-pink-kontkat:before {
  content: "\e00d";
}

.icon-pin-mapa-outline:before {
  content: "\e00e";
}

.icon-oko-model-ikona:before {
  content: "\e00f";
}

.icon-oko-ikona:before {
  content: "\e010";
}

.icon-sygnet-home-kopia:before {
  font-family: "untitled-font-1" !important;
  content: "b";
}

.icon-tweeter:before {
  content: "\e012";
}

.icon-znaczek-stopka:before {
  content: "\e013";
}

.icon-poznaj-rece-ikona:before {
  font-family: "untitled-font-1" !important;
  content: "e";
}

.icon-strzaka-play:before {
  content: "\e015";
}

.icon-strzalka-kwadraty:before {
  content: "\e016";
}

.icon-strzalka-link:before {
  content: "\e017";
}

.icon-noc-ikona:before {
  content: "\e018";
}

.icon-dzien:before {
  content: "\e019";
}

.icon-oko-panorama:before {
  content: "\e01a";
}

.icon-oko:before {
  font-family: "untitled-font-1" !important;
  content: "c";
}

.icon-3dikona:before {
  font-family: "untitled-font-1" !important;
  content: "a";
}

.icon-plusik-07:before {
  content: "\e01d";
}

.icon-zegar-10:before {
  content: "\e01e";
}

.icon-rczki-ikona:before {
  content: "\e01f";
}

.icon-punktor-14:before {
  content: "\e020";
}

.icon-kamera-11:before {
  content: "\e021";
}

.icon-komputer-12:before {
  content: "\e022";
}

.icon-logologo:before {
  font-family: "untitled-font-1" !important;
  content: "b";
}

.icon-mapa:before {
  content: "\e024";
}

.icon-play-16:before {
  content: "\e025";
}

.icon-ikony-dziedzictwo-01:before {
  content: "\e026";
}

.icon-ikony-dziedzictwo-02:before {
  content: "\e027";
}



.icon-n1:before {
  font-family: "untitled-font-1" !important;
  content: "a";
}

.icon-n2:before {
  font-family: "untitled-font-1" !important;
  content: "b";
}

.icon-n3:before {
  font-family: "untitled-font-1" !important;
  content: "c";
}

.icon-n4:before {
  font-family: "untitled-font-1" !important;
  content: "d";
}

.icon-n5:before {
  font-family: "untitled-font-1" !important;
  content: "e";
}

.icon-strzalka-link {
  margin-left: 15px;
  
  display: inline-block;
  vertical-align: middle;

  font-size: 28px;
  line-height: 1;
}

.icon-slider-braz-strzalka {
  color: $font-default-color;
  font-size: 50px;
  display: block;
}

