.modal-open {
    overflow: hidden;
}

.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}

.modal-content {
    position: relative;
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000000;
}

.modal-backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0);
}

.modal-backdrop.in {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.modal-header {
    font-family: $font-family-secondary;
    font-weight: 400;
    padding: 15px;
    padding-bottom: 0;
    position: relative;
    .icon-close {
        cursor: pointer;
        font-size: 20px;
        position: absolute;
        right: 15px;
        top: 23px;
    }
}

.modal-header .close {
    margin-top: -2px;
}

.modal-header-secondary {
    @extend .modal-header;
    background: #f5f5f5;
}

.modal-title {
    margin: 0;
    line-height: 1.42857143;
    font-weight: 400;
    font-size: 25px;
}

@include media("<tablet") {
    .modal-title {
        padding-right: 20px;
    }
}

.modal-body {
    position: relative;
    padding: 15px;
    .tab-default-content {
        // padding-top: 18px;
        hr {
            margin: 0;
        }
        .tab-pane {
            padding-top: 18px;
        }
    }
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.modal-footer .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0;
}

.modal-footer .btn-group .btn + .btn {
    margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
    margin-left: 0;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 768px) {
    .modal-content {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .modal-sm {
        width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
    }
}

.clearfix:before,
.clearfix:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
    content: " ";
    display: table;
}

.clearfix:after,
.modal-header:after,
.modal-footer:after {
    clear: both;
}

.file-modal {
    .modal-header {
        margin-bottom: 22px;
    }
    .modal-body {
        p {
            margin-bottom: 27px;
        }
        button {
            &.btn-primary {
                margin-bottom: 30px;
            }
        }
    }
}
