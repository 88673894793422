//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$grey:                      #eaeff5;

$brand-primary:             #3762a8;
$brand-primary-light:       #007eff;
$brand-primary-lightest:    #78bef0;

$brand-secondary:           #ad003b;
$brand-secondary-primary:   #d9004a;
$brand-secondary-line:      #c3002b;
$brand-secondary-font:      #d30f54;
$brand-secondary-light:     #fad6dd;

$clay: #af5f46;

$colors: (
  'brown-100': #efe6dd,
  'dark-beige': #b58c61,
  'brown': #b5997c,
  'earth': #a06249,
  'turquoise': #4c9099,
);

//== Layout base
//
//## Base
$body-bg:                   #fff;
$text-color:                #2d2d2d;


//== Typography
//
$font-family-base:          "GTAmerica", sans-serif !default;
$font-family-secondary:     "GTSectra", Arial, sans-serif !default;

$font-color-light: (
  '100': #f4f1ee,
  '200': #f4efeb,
  '300': #f1eae3,
  '400': #efe6dd, //default
);

$font-default-color: map-get($font-color-light, '400');

$font-size-sm:              12px;
$font-size-base:            14px;
$font-size-md:              16px;
$font-size-lg:              18px;

$font-size-h1:              35px;
$font-size-h1-lg:           45px;
$font-size-h2:              25px;
$font-size-h2-lg:           30px;
$font-size-h3:              18px;
$font-size-h3-lg:           23px;
$font-size-h4:              20px;
$font-size-h5:              16px;
$font-size-h6:              12px;
$font-text-large-lg:        25px;
$font-text-large:           20px;
$font-text-large-xs:        18px;

$line-height-base:          1.25;
$line-height-computed:      1em;

$headings-font-family:      inherit;
$headings-font-weight:      100;
$headings-line-height:      1.25;
$headings-color:            inherit;


//Messages 
//
$warrning:              orange;
$error:                 #b42547;
$success:               green;


//== Layout
//

// Media queries breakpoints
//
$breakpoints:               (phone: 320px, phone-lg:425px, tablet: 740px, desktop: 1010px, desktop-lg: 1220px);


// Container width
//
$container-lg:              1180px;
$container-md:              960px;
$container-sm:              740px;


// Grid system
//
$grid-columns:              12;
$grid-gutter-width:         30px;

// Z-index master list
//
$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-link-mapa:         1050 !default;
$zindex-menu:              1055 !default;
$zindex-menu-btn:          1056 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-modal-background:  1140 !default;
$zindex-modal:             1150 !default;
$zindex-modal-icon:        1155 !default;


//== Header
//

//== Forms
//
$input-bg:                       #fff;
$input-bg-disabled:              gray;
$input-color:                    #000;
$input-color-placeholder:        #ccc;
$input-border:                   #ccc;

$input-font-size:                18px;
$input-height:                   40px;
$input-padding:                  5px 15px;

$form-group-margin-bottom:       15px;
$form-group-action-margin-top:   30px;

$label-control-font-size:        16px;
$cursor-disabled:                not-allowed;


//== Buttons
//
$btn-font-weight:           normal;
$btn-font-size: 	        16px;
$btn-font-size-lg:          24px;
$btn-padding:               8px 29px;
$btn-padding-lg:            17px 60px;

$btn-default-color:         #000;
$btn-primary-color:         #fff;


//== Dropdowns
//
$dropdown-bckg:              #fff;


//== Border
//
$border-underline:           #ccc;


//== Tooltips
//
$tooltip-max-width:           220px !default;
$tooltip-color:               #000;
$tooltip-bg:                  #FFF;

//== Transition
//
$default-transition-time: 0.2s;
$default-transition-animation: linear;

//== Dialog
//
$dialog-bg: #efe6dd;

//== Dialog
//
$video-overlay: rgba(45, 42, 39, 0.54);