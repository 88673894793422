//
// Grid system
// --------------------------------------------------


// Container widths
//
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
  width: 100%;
  @include media(">=tablet") {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
  @include media(">=desktop") {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    width: $container-md;
  }
  @include media(">=desktop-lg") {
    width: $container-lg;
  }
}

.conainer-relative {
  @extend .container;
  position: relative;
  overflow: hidden;
}


// Row
//
.row {
  @include make-row;
  
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

// Columns
//
@include make-grid-columns;
@include make-grid(xs);

@include media("<desktop") {
  [class*="col-"] {
    width: 100%;
    display: block;
  }
}
@include media(">=tablet") {
  @include make-grid(sm);
}
@include media(">=desktop") {
  @include make-grid(md);
}
@include media(">=desktop-lg") {
  @include make-grid(lg);
}