//
// Utility classes
// --------------------------------------------------

.clearfix {
  @include clearfix;
}
.center-block {
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.hidden {
  display: none;
}
.show {
  display: block;
}
.invisible {
  visibility: hidden;
}

// Responsive images
.img-responsive {
  @include img-responsive;
  &.center{
    margin-left: auto;
    margin-right: auto;
  }
}

// Perfect circle
.img-circle {
  border-radius: 50%;
}

.lh-28 {
  line-height: 28px;
}

.mt-42 {
  margin-top: 42px;
}