//
// Mixins
// --------------------------------------------------
//
// add web prefix to css
// $type - css arg e.g. transform
// $value - css value e.g. rotate(-90deg)
//
@mixin web-prefix($type, $value) {
    #{$type}: $value;
    /* Safari */
    -webkit-#{$type}: $value;
    /* Firefox */
    -moz-#{$type}: $value;
    /* IE */
    -ms-#{$type}: $value;
    /* Opera */
    -o-#{$type}: $value;
}

//make row vertical align middle
@mixin make-vertical-align-middle() {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -ms-flex-align: center;
    -ms-flex: 1 1 auto;
}

@mixin make-vertical-align-top() {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
    display: flex;
    align-items: flex-start;
    -ms-flex-align: center;
}

@mixin make-vertical-align-bottom() {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    align-items: flex-end;
    -ms-flex-align: center;
}

@mixin make-vertical-align-middle-right() {
    display: -webkit-flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -ms-flex-pack: end;
    -ms-flex-line-pack: end;
    -ms-flex-align: center;
    justify-content: flex-end;
}

//
// Animations 
// $time - time in secondcs
//
@mixin transition($what, $animation, $time) {
    -webkit-transition: $what $animation #{$time}s;
    -moz-transition: $what $animation #{$time}s;
    -ms-transition: $what $animation #{$time}s;
    -o-transition: $what $animation #{$time}s;
    transition: $what $animation #{$time}s;
    -webkit-transform: translateZ(0);
}

@mixin infiniteAnimation($animation, $duration, $delay) {
    animation: $animation $duration $delay infinite;
    -moz-animation: $animation $duration $delay infinite;
    -webkit-animation: $animation $duration $delay infinite;
    -o-animation: $animation $duration $delay infinite;
}

@mixin resetTransition() {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    -webkit-transform: none;
}

// Button variants
//
@mixin button-variant($color, $background, $border, $color-hover, $background-hover, $border-hover) {
    color: $color;
    background-color: $background;
    border-color: $border;
    &:focus,
    &.focus {
        outline: none;
        color: $color-hover;
        background-color: $background-hover;
        border-color: $border-hover;
    }
    &:hover {
        color: $color-hover;
        background-color: $background-hover;
        border-color: $border-hover;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color-hover;
        background-color: $background-hover;
        border-color: $border-hover;
        &:hover,
        &:focus,
        &.focus {
            color: $color-hover;
            background-color: $background-hover;
            border-color: $border-hover;
        }
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
    }
}

// Button sizes
//
@mixin button-size($padding, $font-size) {
    padding: $padding;
    font-size: $font-size;
}

// Responsive image
//
@mixin img-responsive($display: block) {
    display: $display;
    max-width: 100%;
    height: auto;
}

// Style focus
//
@mixin tab-focus() {
    outline: thin dotted;
    outline: 1px auto;
    outline-offset: -1px;
}

// Clearfix
//
@mixin clearfix() {
    clear: both;
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

// List unstyled
//
@mixin list-unstyled {
    padding-left: 0;
    list-style: none;
}

@mixin reset-text {
    font-family: $font-family-base;
    // We deliberately do NOT reset font-size.
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: $line-height-base;
    text-align: left; // Fallback for where `start` is not supported
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
}

// Grid system
//
// Centered container element
@mixin container-fixed($gutter: $grid-gutter-width) {
    margin-right: auto;
    margin-left: auto;
    padding-left: floor(($gutter / 2));
    padding-right: ceil(($gutter / 2));
    @include clearfix;
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
    margin-left: ceil(($gutter / -2));
    margin-right: floor(($gutter / -2));
    @include clearfix;
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    float: left;
    width: percentage(($columns / $grid-columns));
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
}

@mixin make-xs-column-offset($columns) {
    margin-left: percentage(($columns / $grid-columns));
}

@mixin make-xs-column-push($columns) {
    left: percentage(($columns / $grid-columns));
}

@mixin make-xs-column-pull($columns) {
    right: percentage(($columns / $grid-columns));
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
    @media (min-width: $screen-sm-min) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}

@mixin make-sm-column-offset($columns) {
    @media (min-width: $screen-sm-min) {
        margin-left: percentage(($columns / $grid-columns));
    }
}

@mixin make-sm-column-push($columns) {
    @media (min-width: $screen-sm-min) {
        left: percentage(($columns / $grid-columns));
    }
}

@mixin make-sm-column-pull($columns) {
    @media (min-width: $screen-sm-min) {
        right: percentage(($columns / $grid-columns));
    }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
    @media (min-width: $screen-md-min) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}

@mixin make-md-column-offset($columns) {
    @media (min-width: $screen-md-min) {
        margin-left: percentage(($columns / $grid-columns));
    }
}

@mixin make-md-column-push($columns) {
    @media (min-width: $screen-md-min) {
        left: percentage(($columns / $grid-columns));
    }
}

@mixin make-md-column-pull($columns) {
    @media (min-width: $screen-md-min) {
        right: percentage(($columns / $grid-columns));
    }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
    @media (min-width: $screen-lg-min) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}

@mixin make-lg-column-offset($columns) {
    @media (min-width: $screen-lg-min) {
        margin-left: percentage(($columns / $grid-columns));
    }
}

@mixin make-lg-column-push($columns) {
    @media (min-width: $screen-lg-min) {
        left: percentage(($columns / $grid-columns));
    }
}

@mixin make-lg-column-pull($columns) {
    @media (min-width: $screen-lg-min) {
        right: percentage(($columns / $grid-columns));
    }
}

// Framework grid generation
// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
    }
    #{$list} {
        // Prevent columns from collapsing when empty
        min-height: 1px;
        // Inner gutter via padding
        padding-left: ceil(($grid-gutter-width / 2));
        padding-right: floor(($grid-gutter-width / 2));
    }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col-#{$class}-#{$i}";
    }
    #{$list} {
        float: left;
    }
}

@mixin calc-grid-column($index, $class, $type) {
    @if ($type==width) and ($index > 0) {
        .col-#{$class}-#{$index} {
            width: percentage(($index / $grid-columns));
        }
    }
    @if ($type==push) and ($index > 0) {
        .col-#{$class}-push-#{$index} {
            left: percentage(($index / $grid-columns));
        }
    }
    @if ($type==push) and ($index==0) {
        .col-#{$class}-push-0 {
            left: auto;
        }
    }
    @if ($type==pull) and ($index > 0) {
        .col-#{$class}-pull-#{$index} {
            right: percentage(($index / $grid-columns));
        }
    }
    @if ($type==pull) and ($index==0) {
        .col-#{$class}-pull-0 {
            right: auto;
        }
    }
    @if ($type==offset) {
        .col-#{$class}-offset-#{$index} {
            margin-left: percentage(($index / $grid-columns));
            @include media("<desktop") {
                margin-left: 0;
            }
        }
    }
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
    @for $i from 0 through $columns {
        @include calc-grid-column($i, $class, $type);
    }
}

// Create grid for specific class
@mixin make-grid($class) {
    @include float-grid-columns($class);
    @include loop-grid-columns($grid-columns, $class, width);
    @include loop-grid-columns($grid-columns, $class, pull);
    @include loop-grid-columns($grid-columns, $class, push);
    @include loop-grid-columns($grid-columns, $class, offset);
}

@mixin paragraph-definition() {
    p {
        margin: 0 0 20px;
    }
}

@mixin section-default($gutter: $grid-gutter-width) {
    padding: $gutter;
    @include media(">=tablet") {
        padding: $gutter*2;
    }
    @include media(">=desktop") {
        padding: 100px;
    }
}

@mixin flex-list() {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 0;
  
    > li {
      &:last-child {
        margin-right: 0;
      }
    }
}