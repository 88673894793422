[class^=section-color-] {
  color: $font-default-color;
}

section {
  position: relative;
}

.section-color-1 {
  background: map-get($colors, 'brown');
}

.section-color-2 {
  background: map-get($colors, 'turquoise');
}

.section-color-3 {
  color: $clay;
  background: map-get($colors, 'brown-100');

  a {
    color: $clay;

    &::after {
      background: $clay;
    }
  }

  .section-pagination {
    color: $clay;
  }
}

.section-parallax {
  position: relative;
  height: 80%;
  overflow: hidden;
}

.section-flex,
.list-flex {
  display: flex;
}

.section-flex > div {
  padding: 0 20px 0 0;
  flex: 1 1 33%;
}

.list-flex {
  &.list-flex-margin-bottom {
    margin-bottom: 70px;
  }

  > li {
    flex: 1 1 0;
    padding: 0 40px;

    font-family: $font-family-base;
    font-size: 16px;

    &:first-child {
      padding-left: 0;
    }
  
    &:last-child {
      padding-right: 0;
    }

    .subheader {
      margin-bottom: 10px;
      font-weight: 600;
    }

    h4 {
      margin-bottom: 30px 0 25px;
      font-size: 30px;

      i {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;

        font-size: 40px;
      }
    }

    a {
      display: block;
      margin: 20px 0 50px;
    }

  }
}

.section-title {
  margin-bottom: 60px;

  font-size: 72px;
  line-height: 86px;
}

.section-title-0-margin {
  margin-top: 0;
}

.section-desc {
  margin-bottom: 30px; 
  font-size: 18px;
  line-height: 36px;

  p {
    padding-right: 2em;
  }

  > h2 {
    margin-top: 0;
  }
}

.section-list {
  padding-top: 20px;
}

.section-pagination {
  margin-bottom: 15px;

  font-family: $font-family-secondary;
  font-size: 30px;
  font-style: italic;
  color: $font-default-color;
}

.parallax-window {
  height: 80vh;
  background: transparent;
}

.section-tile {
  display: block;

  &.tile-full {
    .tile-link {
      padding: 30px;
    }
  }
}

.section-tile-v {
  display: flex;
  align-items: flex-start;
}

.section-tile-gallery {
  .tile-images {
    >* {
      border: 10px solid #efe6dd;
    }
  }
}

.model-wrapper {
  img {
    height: 750px;
    max-height: 100vh;
  }
}

.section-map {
  position: relative;
  z-index: 1;
}

.section-icons {
  justify-content: space-between;
  font-family: $font-family-secondary;

  > div {
    text-align: center;
    font-size: 22px;
  }

  i {
    display: block;
    font-size: 135px;
  }
}

.section-picture {
  height: 80vh;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.section-footer {
  font-family: $font-family-secondary;
  font-size: 42px;
}

.section-logo {
  margin-top: 50px;
  i {
    font-size: 50px;
  }

  .logo {
    margin-right: 80px;
  }
}

.section-img {
  img {
    max-height: 650px;
    max-width: 100%;
  }
}

.section-sponsors {
  .sponsors-logo {
    margin: 0;
  }
}

.section-contact {
  i {
    font-size: 130px;
  }
}

.section-partners {
  margin-bottom: 60px;

  a {
    font-family: $font-family-secondary;
  }

  img {
    max-height: 150px;
    height: 20vw;

    margin-top: 10px;
  }

  .partners-tel-info {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 36px;
    font-family: $font-family-base;
  }
}

.section-thematic-break {
	margin: 90px 0 60px;
}

.section-4-1 {
  .section-icons {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .row {
    text-align: center;
  }
}

@include media("<desktop") {
  .section-icons {
    & > div {
      margin-bottom: 60px;
    }

    i {
      font-size: 80px;
      line-height: 60px;
    }
  }

  .section-img {
    img {
      height: 90vw;
    }
  }

  .section-flex,
  .list-flex {
    display: block;
  }

  .list-flex {
    margin-bottom: 0px;

    &.list-flex-margin-bottom {
      margin-bottom: 0px;
    }
      
    > li {
      padding: 0;
      margin-bottom: 30px;
    }
  }

  .section-footer,
  .section-title {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 1.31;
  }

  .section-desc {
    font-size: 16px;
    line-height: 1.88;

    p {
      padding-right: 0;
    }

    &.section-desc-left-column {
      margin-bottom: 0;
    }
  }

  .section-pagination {
    font-size: 18px;
  }

  .section-map {
    margin: 0;

    .map-outline img {
      width: 100%;
    }
  }

  .section-partners {
    img {
      max-width: 150px;
      width: 40vw;

      max-height: initial;
      height: auto;
    }

    #fwl-logo {
      max-width: 350px;
      width: 80vw;
    }
  }  
  
  .section-tile-v {
    display: block;

    .tile-movie, .tile-text {
      width: 100%;
    }
  }

  .section-4-1 {
    .section-icons {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

@include media("<tablet") {
  .section-tile-v {
    display: block;
  }

  .section-with-video {
    .container-fluid.section-default {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .section-thematic-break {
    margin: 50px 0;
  }
}

@include media("<phone-lg") {
  .tile-models {
    >* {
      flex: 1 1 90%;
      max-width: 90%;
    }
  }

  .section-with-img {

    &.section-desc {
      margin-bottom: 0;
      padding-top: 0;
    }

    img {
      margin: 5px 0 !important;
      width: 100% !important;
      float: none !important;
    }
  }
}
