.tile-image {
  @extend .tile-image;

  &.image-modele {
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }

  &.image-modele-1 {
    background: url('../assets/images/modele/1.png') center;
    background-size: cover;
  }

  &.image-modele-2 {
    background: url('../assets/images/modele/2.png') center;
    background-size: cover;
  }

  &.image-modele-3 {
    background: url('../assets/images/modele/3.png') center;
    background-size: cover;
  }

  &.image-modele-4 {
    background: url('../assets/images/modele/4.png') center;
    background-size: cover;
  }

  &.image-modele-5 {
    background: url('../assets/images/modele/5.png') center;
    background-size: cover;
  }

  &.image-modele-6 {
    background: url('../assets/images/modele/6.png') center;
    background-size: cover;
  }

  &.image-modele-7 {
    background: url('../assets/images/modele/7.png') center;
    background-size: cover;
  }

  &.image-modele-big {
    background: url('../assets/images/modele/model.png') center;
    background-size: cover;
  }
}

.tile-gallery {
  position: relative;

  &:hover {
    .tile-gallery-bckg {
      opacity: 1;
    }

    >span {
      opacity: 1;
    }
  }

  .tile-gallery-bckg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(76, 147, 153, 0.63);
    opacity: 0;
    content: '';
    transition: opacity .2s linear;
    will-change: opacity;
    z-index: 0;
  }

  >span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    text-align: center;
    opacity: 0;
    transition: opacity .2s linear;
    will-change: opacity;
    z-index: 10;

    > * {
      display: block;
      color: #fff;
    }

    i {
      position: relative;
      display: block;
      margin-bottom: 10px;
      font-size: 77px;
    }

    span {
      font-size: 15px;
    }
  }
}

.model-category-list {
  display: block;
  list-style: none;
  margin: auto;
  margin-bottom: 60px;
  padding: 0;
  font-size: 22px;

  > li {
    margin-right: 5%;
    margin-bottom: 1em;
    position: relative;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }

    a.active::after {
      width: 100%;
    }
  }
}

.sketchfab-embed-wrapper
{
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

#dialog, #dialog-polityka {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;

  padding: .2em;
  outline: 0;
  z-index: $zindex-modal-background;
  background: $dialog-bg;
  color: $clay;

  &.show-model {
    display: block;
  }

  .container-fluid.section-default {
    padding: 0px;
  }

  .close-dialog {
    font-size: 60px;
    line-height: 0;
    border-radius: 100%;
    display: block;
    position: fixed;
    top: 10px;
    right: 30px;
    z-index: $zindex-modal-icon;
    cursor: pointer;

    .close {
      color: $clay;
    }
  }
}

@include media("<desktop") {
  .model-category-list { 
    margin-bottom: 30px;
    > li {
      margin-right: 30px;
    }
  }
}