.btn-default {
    background: $btn-default-color;
    border-radius: 0;
    border: 0px;
    color: $btn-primary-color;
    font-family: $font-family-base;
    font-size: 16px;
    padding: 9px 30px;
    text-transform: uppercase;
    @include transition(all, ease, $default-transition-time);
    &:hover,
    &:active,
    &:focus {
        border: 0px;
        border-radius: 0;
        color: #ccc;
        outline: none;
    }
    &:hover {
        color: #fff;
    }
    &.red-btn {
        color: #fff;
    }
}

.btn-primary {
    @extend .btn-default;
    background: $btn-primary-color;
    color: $btn-default-color;
    &:hover {
        background: $btn-primary-color;
    }
}
