.ui-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vw;

  padding: .2em;
  outline: 0;
  z-index: $zindex-modal-background;
  background: $dialog-bg;
  color: $clay;

  * {
    z-index: $zindex-modal;
  }
  
 .ui-dialog-titlebar {
    position: relative;
    padding: .4em 1em;
    margin: 0 auto;

    width: 1180px;

    text-align: right;
  }

  .ui-dialog-title {
    float: left;
    margin: .1em 0;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ui-dialog-titlebar-close {
    position: relative;

    margin: 0;
    padding: 0;

    font-size: 40px;

    color: $clay;
    background: none;
    border: 0;

    cursor: pointer;
    
    &:hover,
    &:focus {
      outline: none;
    }
  }

  .ui-dialog-content {
    position: relative;
    border: 0;
    padding: .5em 1em;
    background: none;
    overflow: auto;
    width: 100% !important;
  }
  
  .ui-dialog-buttonpane {
    text-align: left;
    border-width: 1px 0 0 0;
    background-image: none;
    margin-top: .5em;
    padding: .3em 1em .5em .4em;
  }
  
  .ui-dialog-buttonpane .ui-dialog-buttonset {
    float: right;
  }
  
  .ui-dialog-buttonpane button {
    margin: .5em .4em .5em 0;
    cursor: pointer;
  }
  
  .ui-resizable-handle {
    display: none;
  }

  .container-fluid.section-default {
    padding: 30px;
  }
}

.icon-close-model {
  &:hover,
  &:focus {
    outline: none;
  }
}

.ui-button-icon-space,
.ui-widget-overlay {
  display: none;
}

.dialog-header {
  font-family: $font-family-secondary;
  h2 {
    font-size: 54px;
  }

  h3 {
    font-size: 30px;
  }
}

.dialog-wrapper {
  img {
    width: 100%;
  }
}

.dialog-list {
  padding: 30px 0 0;
  margin: 0;
  list-style: none;

  > li {
    padding-bottom: 15px;
    margin-bottom: 15px;
    line-height: 38px;
    border-bottom: 1px solid $clay;
  }
}

.dialog-item-title {
  font-family: $font-family-secondary;
  font-size: 22px;
}

.dialog-item-desc {
  font-family: $font-family-base;
  font-size: 18px;
}

.dialog-desc {
  p, li {
    font-size: 18px;
    line-height: 36px;
  }
}

.dialog-img-wrapper {
  height: 75vh;
  padding: 30px 0;
}

.col-2 {
  padding-left: 50px;
}

.dialog-carousel-wrapper {
  display: flex;
  align-items: center;
}

.dialog-carousel {
  width: 80%;
  .slider-img-wrapper {
    width: 181px;
    height: 181px;
    img {
      width: 100%;
    }
  }
}

.dialog-pagination {
  width: 20%;
  text-align: center;
  cursor: pointer;
  i {
    font-size: 60px;
  }
}

#model-embed-wrapper {
  border-top: 1px solid $clay;
  border-bottom: 1px solid $clay;
}

@include media("<tablet") {
  .ui-widget-content {
    .container-fluid {
      padding: 0;
    }
  }

  .ui-dialog {
    .ui-dialog-titlebar {
      position: fixed;
      top: 10px;
      right: 10px;
      z-index: $zindex-modal-icon;
      widows: 100%;
    }
  }

  .dialog-img-wrapper {
    height: auto;
  }

  .dialog-header h2 {
    font-size: 32px;
  }

  #model-embed {
    display: block;
    margin: 0;
    width: 95vw;
    max-width: 90vh;
    height: 95vh;
    max-height: 90vw;
  }
}

@include media("<phone-lg") {
  .ui-dialog {
    .container-fluid.section-default {
      padding: 0;
    }
  }
}
