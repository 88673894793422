.carousel-wrapper {
  position: relative;
  width: calc(100% - 50px);

  .carousel {
    position: inherit;
  }
  
  .carousel-next-link,
  .slick-dots a {
    &:hover{
      &::after {
        content: none;
      }
    }
  }

  .carousel-next-link {
    i {
      font-size: 100px;
    }
  }

  .carousel-next-link-centered {
    position: absolute;
    margin: 0 0 50px 0;
    bottom: 0;
    right: 15%;
    height: 50%;
    text-align: center;
  }

  &.carousel-with-desc {
    .carousel-next-link-centered {
      right: -25%;
    }
  }
}

@include media('<desktop-lg') {
  .carousel-wrapper {
    .section-desc {
      font-size: 16px;
      line-height: 1.88;
    }
  }
}

@include media('<desktop') {
  .carousel-wrapper {
    .carousel-next-link {
      visibility: hidden;
    }
  }
}
