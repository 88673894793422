//
// Forms
// --------------------------------------------------
fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
}

legend {
    text-align: center;
    padding: 10px 0;
    display: block;
    margin: 0;
}

label {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 6px;
    position: relative;
}

input {
    &:active,
    &:focus {
        outline: none;
    }
}

.control-label {
    @extend .text-label;
    font-size: 14px;
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
    line-height: normal;
}

input[type="checkbox"] + label {
    margin-bottom: 0;
}

input[type="file"] {
    display: block;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
    height: auto;
}

// Focus for file, radio, and checkbox
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: none;
}

// Common form controls
//
.form-control {
    display: block;
    width: 100%;
    height: $input-height;
    padding: $input-padding;
    font-family: $font-family-base;
    font-size: $input-font-size;
    line-height: $line-height-base;
    color: $input-color;
    background-color: $input-bg;
    background-image: none;
    border: 1px solid $input-border;
    border-radius: 0;
    box-shadow: none;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    appearance: none;
    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        border: 0;
        background-color: transparent;
    }
    &:placeholder {
        color: $input-color-placeholder;
        opacity: 1;
    }
    // Disabled and read-only inputs
    &[disabled],
    &[readonly],
    fieldset[disabled] & {
        background-color: $input-bg-disabled;
        opacity: 1;
    }
    &[disabled],
    fieldset[disabled] & {
        cursor: $cursor-disabled;
    }
    &.white-version {
        background: #fff;
    }
}

.form-control-inline {
    @extend .form-control;
    display: inline-block;
    vertical-align: middle;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

// Reset height for `textarea`s
textarea.form-control {
    height: auto;
    resize: none;
}

// Control variant
//
.form-control-dark {
    background: rgb(35, 42, 60); //#433d42;
    color: #d0c9d0;
    &:focus {
        // background: #433d42;
        color: #d0c9d0;
        outline: none;
    }
}

.form-control-blue {
    // background: $blue-base;
    color: #fff;
    font-size: 15px;
    &:focus {
        outline: none;
    }
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &:-ms-input-placeholder {
        // color: $blue-light;
    }
}

.form-group-search {
    padding: 0;
    .input-group-btn {
        button {
            // background: $blue-base;
            border-width: 0px;
            color: #fff;
            margin-left: 0px;
            &:hover {
                // background: $blue-base;
                .icon-search {
                    // color: $blue-normal;
                }
            }
            &:active,
            &:target {
                border-width: 0;
                color: #fff;
                outline: none;
            }
            &.btn-default {
                padding-right: 10px;
            }
            .icon-search {
                position: relative;
                top: 3px;
                @include transition(all, linear, $default-transition-time);
            }
        }
    }
}

@include media("<tablet") {
    .form-group-search {
        .form-control {
            font-size: 14px;
        }
    }
}

// Form groups
//
.form-group {
    margin-bottom: 10px;
    position: relative;
}

@include media("<tablet") {
    .form-group {
        &.vertical-align-middle {
            display: block;
        }
    }
}

.form-group-action {
    margin-top: $form-group-action-margin-top;
}

// Checkboxes and radios
//
.radio,
.checkbox {
    position: relative;
    display: block;
    margin: 15px 0;
    label {
        min-height: 16px;
        padding: 0 0 0 27px;
        margin-bottom: 0;
        font-weight: normal;
        cursor: pointer;
        position: relative;
        @extend .text-label;
        &.label-normal {
            font-size: 14px;
            text-transform: none;
        }
        &.text-sm {
            font-size: 12px;
        }
    }
}

.checkbox {
    label {
        padding: 0;
    }
}

.radio input[type="radio"] {
    opacity: 0;
    + i {
        display: block;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            width: 16px;
            height: 16px;
            border: 1px solid #909a9b;
            display: block;
            z-index: 2;
            border-radius: 50%;
        }
    }
    &:checked + i {
        &:after {
            position: absolute;
            left: 3px;
            top: 3px;
            content: '';
            width: 10px;
            height: 10px;
            text-align: center;
            display: block;
            z-index: 3;
            border-radius: 50%;
        }
    }
}

.checkbox input[type="checkbox"] {
    + i {
        display: block;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            width: 20px;
            height: 20px;
            background: $input-bg;
            display: block;
            z-index: 2;
        }
    }
    &:checked + i {
        &:after {
            color: $input-color;
            position: absolute;
            left: 0;
            top: 0;
            content: '\2713';
            background: $input-bg;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            display: block;
            z-index: 3;
        }
    }
}

.radio input[type="radio"],
.checkbox input[type="checkbox"],
{
    position: absolute;
    margin-left: -27px;
}

.radio + .radio,
.checkbox + .checkbox {
    margin-top: -5px;
}

input[type="radio"],
input[type="checkbox"] {
    &[disabled],
    &.disabled,
    fieldset[disabled] & {
        cursor: $cursor-disabled;
    }
}

.radio-inline,
.checkbox-inline {
    display: inline-block;
    padding-right: 20px;
}

@include media("<desktop") {
    .radio-inline,
    .checkbox-inline {
        display: block;
    }
}

@include media("<tablet") {
    .radio-inline {
        margin-bottom: 10px;
    }
}

// Form control feedback states
//
// Apply contextual and semantic states to individual form controls.
.has-feedback {
    // Enable absolute positioning
    position: relative;
    // Ensure icons don't overlap text
    .form-control {
        padding-right: ($input-height * 1.25);
    }
}

// Feedback icon (requires .glyphicon classes)
.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2; // Ensure icon is above input groups
    display: block;
    width: $input-height;
    height: $input-height;
    line-height: $input-height;
    text-align: center;
    pointer-events: none;
}

// Feedback states
.has-success {
    ///@include form-control-validation($state-success-text, $state-success-text, $state-success-bg);
}

.has-warning {
    //@include form-control-validation($state-warning-text, $state-warning-text, $state-warning-bg);
}

.has-error {
    //@include form-control-validation($state-danger-text, $state-danger-text, $state-danger-bg);
}

// Reposition feedback icon if input has visible label above
.has-feedback label {
    & ~ .form-control-feedback {
        top: ($line-height-computed + 5); // Height of the `label` and its margin
    }
    &.sr-only ~ .form-control-feedback {
        top: 0;
    }
}

.form-control-box {
    position: relative;
}

.form-group.has-action {
    position: relative;
    .form-control {
        padding-right: 40px;
    }
    button {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: $input-height;
        line-height: $input-height;
        background-image: none;
        background: none;
        border: none;
        padding: 0;
        text-align: center;
        font-size: 1.5em;
        cursor: pointer;
        &:focus {
            outline: none;
        }
    }
}

.control-help {
    position: relative;
    span {
        display: block;
        line-height: 15px;
    }
}

// Horizontal forms
//
.form-horizontal {
    margin: 30px 0;
    .radio,
    .checkbox,
    .radio-inline,
    .checkbox-inline {
        //margin-top: 0;
        //margin-bottom: 0;
    }
    .form-group {
        @include make-row;
    }
    .control-label {
        line-height: 40px;
        margin-bottom: 0;
        text-align: right;
        &.big-line-height {
            line-height: 50px;
        }
    }
}

@include media("<desktop-lg") {
    .form-horizontal {
        .form-group {
            display: -webkit-flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
        }
        .control-label {
            line-height: normal;
            &.big-line-height {
                line-height: normal;
            }
        }
    }
}

@include media("<tablet") {
    .form-horizontal {
        margin: 15px 0;
        small {
            margin-bottom: 15px;
        }
        hr {
            margin-bottom: 15px;
            margin-top: -5px;
        }
        .form-group {
            display: block;
            margin-bottom: 15px;
            &.vertical-align-middle-xs {
                @include make-vertical-align-middle();
            }
            p {
                margin: 0px;
                margin-bottom: 10px;
            }
            .radio {
                margin: 0;
                margin-bottom: 10px;
            }
            .radio-inline {
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .checkbox {
                margin: 0;
            }
        }
        .control-label {
            font-size: 14px;
            text-align: left;
            margin-bottom: 5px;
        }
        .form-control {
            margin-bottom: 5px;
        }
        .checkbox {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.delivery-date {
    .row {
        margin-bottom: 30px;
        &:first-child {
            margin-top: 30px;
        }
    }
}

@include media("<tablet") {
    .delivery-date {
        .row {
            margin-bottom: 15px;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .vertical-align-middle {
            display: block;
            > div {
                text-align: left;
            }
        }
        .form-horizontal {
            .vertical-align-middle {
                text-align: center;
                .col-sm-2 {
                    text-align: center;
                    margin-bottom: 5px;
                }
                .col-md-8 {
                    display: inline-block;
                }
            }
        }
    }
}

.payment-method {
    form {
        .row {
            margin-top: -40px;
        }
    }
}

@include media("<tablet") {
    .payment-method {
        .radio {
            margin-bottom: 0;
            label {
                line-height: 14px;
            }
        }
    }
}

@include media("<tablet") {
    .common-error {
        > * {
            display: inline-block;
        }
        span.or {
            margin: 10px 10px !important;
        }
    }
}
