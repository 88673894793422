$footerBg: $clay;

.footer-main {
  font-size: 22px;
  background: $footerBg;
  color: $font-default-color;

  padding: 85px 0;

  .container {
    padding: 80px 0 50px;
  }

  a {
    color: $font-default-color;
    display: inline-block;
    margin-bottom: 20px;
  }

  .row {
    display: flex;
    justify-content: flex-start;
  }
}

.footer-menu-wrapper {
  padding-bottom: 62px;
  margin-bottom: 20px;
  border-bottom: 1px solid $font-default-color;

  > * {
    flex: 2;

    &:first-child {
      flex: 1
    }
  }
}

.footer-address {
  position: relative;
  line-height: 32px;
  font-size: 18px;
}

.footer-links {
  padding-bottom: 62px;
  margin-bottom: 40px;
  border-bottom: 1px solid $font-default-color;

  a {
    font-family: $font-family-secondary;
    font-size: 30px;
    flex: 2;
    
    &:first-child {
      flex: 1
    }
  }

  .look-more {
    margin-top: 0;
  }
}

.back-to-top {
  position: absolute;
  bottom: 0;
  right: 20px;

  font-size: 18px;
  text-align: center;

  &:hover {
    &::after {
      content: none;
    }
  }

  i {
    display: block;
    margin: 20px 0;
    font-size: 80px;
  }
}

.footer-copyright {
  font-size: 15px;
  margin-right: 20px;
  span {
    padding: 0 10px;
    display: inline-block;
  }
}

@include media("<desktop")
{
  .footer-main {

    padding: 40px 0;

    a {
      margin-bottom: 20px;
    }
  
    .row {
      display: block;
    }
  }

  .footer-links {
    padding: 0;
    border: 0;

    a {
      margin: 0;
      padding: 40px 0;
      width: 100%;
      border-bottom: 1px solid $font-default-color;
    }
  }

  .footer-menu-wrapper {
    .footer-menu {
      display: flex;
      margin-bottom: 40px;
      div {
        float: left;
        width: 100%;
      }
    }
  }

  .back-to-top {
    position: relative;
    width: 100%;

    font-size: 18px;
    text-align: center;
  
    i {
      font-size: 80px;
    }
  }
}

@include media("<tablet") {
  .footer-main {
    margin: 0;
    padding: 0;

    .container-fluid {
      padding: 0 20px;
    }
  }

  .footer-menu {
    font-size: 18px;
  }

  .footer-address {
    line-height: 16px;
    font-size: 12px;
  }

  .footer-copyright {
    font-size: 10px;
    margin-left: -15px;
    margin-right: -15px;

    span {
      padding: 0 5px;
    }
  }
}